import { gtagEnabled } from './index'
import { Cart } from '@medusajs/medusa'
import { formatMedusaPrice } from '../formatMedusaPrice'

export const trackBeginCheckout = (cart: Omit<Cart, 'refundable_amount' | 'refunded_total'>) => {
    const items = cart?.items.map(item => {
        return {
            item_name: item.title,
            item_id: item.id,
            quantity: item.quantity,
            price: formatMedusaPrice(item.total),
        }
    })

    if (cart?.total) {
        if (gtagEnabled()) {
            gtag('event', 'begin_checkout', {
                value: formatMedusaPrice(cart?.total),
                currency: 'EUR',
                items,
            })
        }
    }
}
