'use client'
import { medusaClient } from '../config'
import { handleError } from '@lib/util/handle-error'
import { useCart, useCreateLineItem, useDeleteLineItem, useUpdateCart, useUpdateLineItem } from 'medusa-react'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useBoolean } from 'ui/hooks/useBoolean'
import { MedusaError } from 'medusa-core-utils'
import { Cart, Order as MedusaOrder, Product, ProductCategory, Region, StorePostCartsCartReq } from '@medusajs/medusa'
import { trackBeginCheckout } from '../../utils/tracking/trackBeginCheckout'

type ErrorType = { response: { data: MedusaError } }

type SelectValue = {
    label: string
    value: string
}

type CartContextValues = {
    license_plate?: string
    dealer: SelectValue
    newsletter: boolean
    salutation: SelectValue
}

export type CheckoutFormValues = {
    email?: string
    shipping_address?: AddressValues
    billing_address?: AddressValues
    context?: CartContextValues
}

export type VariantInfoProps = {
    variantId: string
    quantity: number
    callBack?: () => void
}

export type LineInfoProps = {
    lineId: string
    quantity: number
}

export type AddressValues = {
    first_name: string
    last_name: string
    company?: string
    address_1: string
    address_2: string
    addition?: string
    city: string
    province?: string
    postal_code: string
    country_code: string
    phone: string
}

export type DealerProps = {
    label: string
    value: string
}[]

interface StoreContext {
    dealers: DealerProps
    setContext: (payload: any) => Promise<void>
    initPayment: () => Promise<void>
    setAddresses: (addresses: CheckoutFormValues) => Promise<Omit<Cart, 'refundable_amount' | 'refunded_total'>>
    allCarModels: ProductCategory[]
    countryCode: string | undefined
    setRegion: (regionId: string, countryCode: string) => void
    addItem: (item: VariantInfoProps) => void
    updateItem: (item: LineInfoProps) => void
    deleteItem: (lineId: string) => void
    resetCart: () => void
    setQuantityError: (value: string | undefined) => void
    quantityError: string | undefined
    lineItemError: LineItemError | undefined
    quantityUpdating?: { [key: string]: boolean }
    completeCart: (cartId: string) => Promise<MedusaOrder | null>
    isCompletingCart: boolean
    isModelDialogOpen: boolean
    snackbarError: string | null
    closeModelDialog: () => void
    openModelDialog: () => void
    firstTimeUser: boolean
    setFirstTimeUser: (value: boolean) => void
    isSearchingProducts: boolean
    setIsSearchingProducts: (value: boolean) => void
}

const StoreContext = React.createContext<StoreContext | null>(null)

export const useStore = () => {
    const context = React.useContext(StoreContext)
    if (context === null) {
        throw new Error('useStore must be used within a StoreProvider')
    }
    return context
}
type LineItemError = {
    lineId: string
    error: string
}
const IS_SERVER = typeof window === 'undefined'
const CART_KEY = 'medusa_cart_id'
const REGION_KEY = 'medusa_region'

export const StoreProvider = ({
    dealers,
    allCarModels,
    children,
}: PropsWithChildren<{
    dealers: DealerProps
    allCarModels: ProductCategory[]
}>) => {
    const { cart, setCart, createCart, updateCart } = useCart()
    const IDEMPOTENCY_KEY = 'create_payment_session_key'
    const [countryCode, setCountryCode] = useState<string | undefined>(undefined)
    const [quantityError, setQuantityError] = useState<string | undefined>(undefined)
    const [lineItemError, setLineItemError] = useState<LineItemError | undefined>(undefined)
    const addLineItem = useCreateLineItem(cart?.id!)
    const removeLineItem = useDeleteLineItem(cart?.id!)
    const adjustLineItem = useUpdateLineItem(cart?.id!)
    const [isCompletingCart, setIsCompletingCart] = useState<boolean>(true)
    const [quantityUpdating, setQuantityUpdating] = useState({})
    const { mutateAsync: updateCheckoutCart } = useUpdateCart(cart?.id!)
    const [snackbarError, setSnackbarError] = useState<string | null>(null)
    const [firstTimeUser, setFirstTimeUser] = useState<boolean>(true)
    const [isSearchingProducts, setIsSearchingProducts] = useState(false)

    /**
     * Method to create the payment sessions available for the cart. Uses a idempotency key to prevent duplicate requests.
     */
    const createPaymentSession = async (cartId: string) => {
        return medusaClient.carts
            .createPaymentSessions(cartId, {
                'Idempotency-Key': IDEMPOTENCY_KEY,
            })
            .then(({ cart }) => cart)
            .catch(() => null)
    }

    /**
     * Method that calls the createPaymentSession method and updates the cart with the payment session.
     */
    const initPayment = async () => {
        if (cart?.id && cart?.items?.length) {
            if (!cart.payment_sessions?.length || cart?.payment_sessions?.length < 1) {
                const paymentSession = await createPaymentSession(cart.id)
                if (paymentSession) {
                    trackBeginCheckout(cart)
                    setCart(paymentSession)
                }
            } else {
                trackBeginCheckout(cart)
            }
        }
    }

    const setContext = async ({ ...payload }: { payload: any }) => {
        await updateCart.mutateAsync(
            {
                context: payload,
            },
            {
                onSuccess: ({ cart }) => {
                    if (cart) {
                        setCart(cart)
                        storeCart(cart?.id)
                    }
                },
                onError: handleError,
            },
        )
    }

    const { value: isModelDialogOpen, setFalse: closeModelDialog, setTrue: openModelDialog } = useBoolean(false)

    const setAddresses = async (data: CheckoutFormValues) => {
        const payload: StorePostCartsCartReq = {
            shipping_address: data?.shipping_address,
            billing_address: data?.billing_address,
            email: data?.email,
            ...(data?.context
                ? {
                      context: {
                          license_plate: data?.context?.license_plate,
                          dealer: data?.context?.dealer,
                          newsletter: data?.context?.newsletter,
                          salutation: data?.context?.salutation,
                      },
                  }
                : {}),
        }

        const response = await updateCheckoutCart(payload)

        return response.cart
    }

    const storeRegion = (regionId: string, countryCode: string) => {
        if (!IS_SERVER) {
            localStorage.setItem(REGION_KEY, JSON.stringify({ regionId, countryCode }))

            setCountryCode(countryCode)
        }
    }

    const getRegion = () => {
        if (!IS_SERVER) {
            const region = localStorage.getItem(REGION_KEY)
            if (region) {
                return JSON.parse(region) as { regionId: string; countryCode: string }
            }
        }
        return null
    }

    const setRegion = async (regionId: string, countryCode: string) => {
        await updateCart.mutateAsync(
            {
                region_id: regionId,
            },
            {
                onSuccess: ({ cart }) => {
                    if (cart) {
                        setCart(cart)
                        storeCart(cart?.id)
                        storeRegion(regionId, countryCode)
                    }
                },
                onError: handleError,
            },
        )
    }

    const ensureRegion = (region: Region, countryCode?: string | null) => {
        if (!IS_SERVER) {
            const { regionId, countryCode: defaultCountryCode } = getRegion() || {
                regionId: region.id,
                countryCode: region.countries[0].iso_2,
            }

            const finalCountryCode = countryCode || defaultCountryCode

            if (regionId !== region.id) {
                setRegion(region.id, finalCountryCode)
            }

            storeRegion(region.id, finalCountryCode)
            setCountryCode(finalCountryCode)
        }
    }

    const storeCart = (id: string) => {
        if (!IS_SERVER) {
            localStorage.setItem(CART_KEY, id)
        }
    }

    const getCart = () => {
        if (!IS_SERVER) {
            return localStorage.getItem(CART_KEY)
        }
        return null
    }

    const deleteCart = () => {
        if (!IS_SERVER) {
            localStorage.removeItem(CART_KEY)
        }
    }

    const deleteRegion = () => {
        if (!IS_SERVER) {
            localStorage.removeItem(REGION_KEY)
        }
    }

    const createNewCart = async (regionId?: string, variables?: any) => {
        await createCart.mutateAsync(
            {
                region_id: regionId,
                ...variables,
            },
            {
                onSuccess: ({ cart }) => {
                    if (cart) {
                        setCart(cart)
                        storeCart(cart?.id)
                        ensureRegion(cart.region)
                    }
                },
                onError: handleError,
            },
        )
    }

    const resetCart = () => {
        deleteCart()

        const savedRegion = getRegion()

        createCart.mutate(
            {
                region_id: savedRegion?.regionId,
            },
            {
                onSuccess: ({ cart }) => {
                    if (cart) {
                        setCart(cart)
                        storeCart(cart?.id)
                        ensureRegion(cart.region)
                    }
                },
                onError: handleError,
            },
        )
    }

    const updateQuantityUpdating = (variantOrLineId: string, updating = true) => {
        setQuantityUpdating(prevState => ({ ...prevState, [variantOrLineId]: updating }))
    }

    const addItem = ({ variantId, quantity, callBack }: VariantInfoProps) => {
        updateQuantityUpdating(variantId)

        addLineItem.mutate(
            {
                variant_id: variantId,
                quantity: quantity,
            },
            {
                onSuccess: ({ cart }) => {
                    if (cart) {
                        setCart(cart)
                        storeCart(cart?.id)
                    }
                    setQuantityError(undefined)
                    callBack && callBack()
                },
                onError: errorObj => {
                    const error = errorObj as unknown as ErrorType
                    if (error.response.data.type === 'not_allowed') {
                        setQuantityError('Er zijn niet genoeg producten op voorraad om deze actie uit te voeren.')
                    } else {
                        setQuantityError('Er ging iets mis, probeer het nogmaals of neem contact met ons op.')
                    }
                },
                onSettled: () => updateQuantityUpdating(variantId, false),
            },
        )
    }

    const deleteItem = (lineId: string) => {
        updateQuantityUpdating(lineId)

        removeLineItem.mutate(
            {
                lineId,
            },
            {
                onSuccess: ({ cart }) => {
                    if (cart) {
                        setCart(cart)
                        storeCart(cart?.id)
                    }
                },
                onError: handleError,
                onSettled: () => updateQuantityUpdating(lineId, false),
            },
        )
    }

    const updateItem = ({ lineId, quantity }: LineInfoProps) => {
        updateQuantityUpdating(lineId)

        adjustLineItem.mutate(
            {
                lineId,
                quantity,
            },
            {
                onSuccess: ({ cart }) => {
                    if (cart) {
                        setCart(cart)
                        storeCart(cart?.id)
                    }
                    setQuantityError(undefined)
                    setLineItemError(undefined)
                },
                onError: (errorObj, payload) => {
                    const error = errorObj as unknown as ErrorType
                    if (error.response.data.type === 'not_allowed') {
                        setLineItemError({
                            lineId: payload?.lineId,
                            error: 'Er zijn niet genoeg producten op voorraad om deze actie uit te voeren.',
                        })
                    } else {
                        setQuantityError('Er ging iets mis, probeer het nogmaals of neem contact met ons op.')
                    }
                },
                onSettled: () => updateQuantityUpdating(lineId, false),
            },
        )
    }

    const completeCart = async (cartId: string) => {
        setIsCompletingCart(true)
        try {
            const data = await medusaClient.carts.complete(cartId)

            if (data.type !== 'order') {
                return null
            }

            setIsCompletingCart(false)
            return data.data
        } catch (e) {
            setSnackbarError('Er ging wat mis met het ophalen van de order')
            setIsCompletingCart(false)
        }
        return null
    }

    useEffect(() => {
        const ensureCart = async () => {
            const cartId = getCart()
            const region = getRegion()

            if (cartId) {
                const cartRes = await medusaClient.carts
                    .retrieve(cartId)
                    .then(({ cart }) => {
                        return cart
                    })
                    .catch(async _ => {
                        return null
                    })

                if (!cartRes || cartRes.completed_at) {
                    deleteCart()
                    deleteRegion()
                    await createNewCart()
                    return
                }

                setCart(cartRes)
                ensureRegion(cartRes.region)
            } else {
                await createNewCart(region?.regionId)
            }
        }

        if (!IS_SERVER) {
            const storedRegion = localStorage.getItem(REGION_KEY)
            if (storedRegion) {
                const { countryCode } = JSON.parse(storedRegion)
                setCountryCode(countryCode)
            }
        }

        if (!IS_SERVER && !cart?.id) {
            ensureCart()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <StoreContext.Provider
            value={{
                dealers,
                countryCode,
                setRegion,
                addItem,
                snackbarError,
                deleteItem,
                updateItem,
                resetCart,
                quantityError,
                setQuantityError,
                lineItemError,
                setAddresses,
                initPayment,
                quantityUpdating,
                setContext,
                completeCart,
                allCarModels,
                isCompletingCart,
                isModelDialogOpen,
                closeModelDialog,
                openModelDialog,
                firstTimeUser,
                setFirstTimeUser,
                isSearchingProducts,
                setIsSearchingProducts,
            }}
        >
            {children}
        </StoreContext.Provider>
    )
}
