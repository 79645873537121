import type { MoneyAmount, PriceSelectionResult } from '@medusajs/medusa'

const getVariantPrice = (prices?: MoneyAmount[]) => {
    if (!prices) {
        return null
    }

    const result: PriceSelectionResult = {
        originalPrice: null,
        calculatedPrice: null,
        prices,
    }

    // Currently not taking region or currency code into account
    for (const ma of prices) {
        delete ma.currency
        delete ma.region

        if (
            // context.region_id &&
            // ma.region_id === context.region_id &&
            ma.price_list_id === null &&
            ma.min_quantity === null &&
            ma.max_quantity === null
        ) {
            result.originalPrice = ma.amount
        }

        if (
            // context.currency_code &&
            // ma.currency_code === context.currency_code &&
            ma.price_list_id === null &&
            ma.min_quantity === null &&
            ma.max_quantity === null &&
            result.originalPrice === null // region prices take precedence
        ) {
            result.originalPrice = ma.amount
        }

        if (
            result.calculatedPrice === null ||
            ma.amount < result.calculatedPrice
            // && ((context.currency_code && ma.currency_code === context.currency_code) ||
            //     (context.region_id && ma.region_id === context.region_id))
        ) {
            result.calculatedPrice = ma.amount
            result.calculatedPriceType = ma.price_list?.type
        }
    }

    return result
}

export default getVariantPrice
