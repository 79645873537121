const messages = {
    // Form fields
    fieldRequired: 'Dit veld is verplicht.',
    postcodeNotValid: 'Vul een postcode in met het formaat 1234AB.',
    phoneNotValid: 'Vul een telefoonnummer in van 10 cijfers.',
    emailAddressNotValid: 'Vul een geldig e-mailadres in.',
    emailDoesNotMatch: 'Ingevoerde e-mailadres komt niet overeen.',
    passwordTooShort: 'Een wachtwoord moet minimaal 8 tekens bevatten.',
    passwordDoNotMatch: 'De wachtwoorden komen niet overeen.',
    unknownError: 'Sorry, er is iets misgegaan aan onze kant. Probeer het opnieuw of neem contact met ons op.',
    emailNotExists: 'Er is geen account bekend voor het opgegeven e-mailadres.',
    emailExists: 'Er bestaat al een account met dit e-mailadres.',
    passwordResetSend: 'Er is een e-mail verstuurd voor het instellen van uw wachtwoord.',
    passwordResetSuccessful: 'Het wachtwoord is opnieuw ingestgeld.',
    expiredToken: 'Het wachtwoord reset token is verlopen.',
    AccountCreationFailed:
        'Er ging wat mis met het aanmaken van je account. Probeer het opnieuw of neem contact met ons op.',
    passwordNotValid: 'Het wachtwoord moet bestaan uit minimaal 8 karakters, 1 hoofdletter en 1 cijfer.',
    unknowUser: 'Sorry, we herkennen uw account niet. Controleer uw login gegevens en probeer het opnieuw.',

    // Content
    inStock: 'Op voorraad',
    notInStock: 'Niet op voorraad',
    addToCart: 'Toevoegen aan winkelmand',
    deliveryUsp: 'Voor 12 uur besteld, binnen 2 werkdagen geleverd bij je dealer.',
    getInTouch: 'Neem contact op',
    allProductsFor: 'Alle producten voor de',
    allFor: 'Alles voor de',
    showAllProductsFor: 'Bekijk alle producten voor de',
    selectModel: 'Selecteer model',
    homeHeroHeader: 'Goed voorbereid op weg',
    modelHeroHeader: 'Goed voorbereid op weg<br />met originele wintersets',
    depositDescription: 'Vandaag betaalt u alleen de aanbetaling, het resterende bedrag betaalt u bij de dealer.',
    officialAccessoires: 'Met de officiële Mitsubishi accessoires bent u verzekerd van de meest complete ervaring.',
    needHelp: 'Hulp nodig?',
    deliveryCost:
        'Alle producten worden naar een Mitsubishi dealer van uw keuze verzonden. Zo garanderen wij de beste service en snelste levertijd.',
}

export default messages
