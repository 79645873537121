import * as React from 'react'
import { PriceProps } from './types'
import { formatMedusaPrice } from '../../utils/formatMedusaPrice'
import classNames from 'classnames'

const Price = ({ price, className, productDetail }: PriceProps) => {
    return (
        <span className={classNames(`font-medium`, className)}>
            <span className={'font-normal'}>€</span> {formatMedusaPrice(price)}
            {productDetail && <span className="block text-xs">(All-in advies prijs)</span>}
        </span>
    )
}
export default Price
